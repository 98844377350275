import L from "leaflet";

export default {
    "vehicles.get": (response, context) => {
        return response;
    },
    "segment.post": (response, context) => {
        return response;
    },
    "plan.post": (response, context) => {
        return response;
    },
    "plan-execute.post": (response, context) => {
        return response;
    },
    "isalive.get": (response, context) => {
        return response;
    },
    "pots.get": (response, context) => {
        return response;
    },
    "stations.get": (response, context) => {
        return response;
    },
    "geocoder.get": (response, context) => {
        return response;
    },
    "reversegeocoder.get": (response, context) => {
        return response;
    },
    "plans-download.post": (response, context) => {
        let osmUsable = false;

        // IJP Prague semantic hotfix
        response.data.plans = response.data.plannedRoutes;

        response.data.plans.forEach((plan, key) => {
            const hardCopy = JSON.parse(JSON.stringify(plan));

            // IJP Prague semantic hotfix
            const extra = {
                allLatLongs: [],
                allLatLongsExclWalk: [],
                warnings: [],
                signature: ""
            };
            
            extra.allLatLongs = [];
            extra.allLatLongsExclWalk = [];
            extra.warnings = [];
            extra.signature = `${plan.legs[0].start.lon},${
                plan.legs[0].start.lat
            }:${plan.legs[plan.legs.length - 1].start.lon},${
                plan.legs[plan.legs.length - 1].start.lat
            }:${plan.legs.length}:${plan.stats.bikeConvenience}:${
                plan.stats.distanceMeters
            }:${plan.stats.durationSeconds}`;

            for (let i = 0; i < plan.segments.length; i++) {
                if (
                    !osmUsable &&
                    plan.segments[i].additionalData &&
                    plan.segments[i].additionalData.osmLinks &&
                    plan.segments[i].additionalData.osmLinks.length
                ) {
                    osmUsable = true;
                } // end if
            } // end for

            for (let leg of plan.legs) {
                extra.allLatLongs.push(
                    L.latLng(
                        leg.start.geoLocation.lat,
                        leg.start.geoLocation.lon
                    )
                );
                if ("WALK" !== leg.mode) {
                    extra.allLatLongsExclWalk.push(
                        L.latLng(
                            leg.start.geoLocation.lat,
                            leg.start.geoLocation.lon
                        )
                    );
                }

                extra.allLatLongs.push(
                    L.latLng(leg.end.geoLocation.lat, leg.end.geoLocation.lon)
                );
                if ("WALK" !== leg.mode) {
                    extra.allLatLongsExclWalk.push(
                        L.latLng(
                            leg.end.geoLocation.lat,
                            leg.end.geoLocation.lon
                        )
                    );
                }
            }

            response.data.plans[key]._ = extra;
            response.data.plans[key].hardCopy = hardCopy;
        });

        context.$store.commit("osmUsable", osmUsable);

        return response;
    },
    "plan-update.post": (response, context) => {
        return response;
    },
};
